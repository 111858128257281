import React from 'react';
import cn from 'classnames';
import b_ from 'b_';
import './Text.scss';

const b = b_.lock('Text');

// const TEXT_TYPES = [
//   'h1',
//   'h2',
//   'h3',
//   'h4',
//   'h5',
//   'h6',
//   'primary',
//   'subtitle'
// ];

const TAGS = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6'
];

const Text = ({
  className,
  type = 'primary',
  tag,
  children,
  ...props
}) => {
  const Component = tag || (TAGS.indexOf(type) > -1 ? type : 'span');

  return (
    <Component
      className={cn(b({ type }), className)}
      {...props}
    >
      {children}
    </Component>
  );
};

export default Text;
