import React from 'react';
import { render } from 'react-snapshot';
import { App } from '../components/App';
import IndexRoute from '../pages/IndexRoute';
import { lazyRoutes } from '../lazyRoutes';

const route = ['/', IndexRoute, true];
const restLazyRoutes = lazyRoutes.filter(([path]) => path !== route[0]);

render(
  <App lazyRoutes={restLazyRoutes} route={route} />,
  document.getElementById('root')
);
