import React from 'react';
import b_ from 'b_';
import Helmet from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '../Button';
import { Text } from '../Text';
import { Link } from '../Link';
import printer from './thermal-printer.svg';
import './Hero.scss';

const b = b_.lock('Hero');

const Hero = ({ path = '/' }) => (
  <div className={b()} to={path}>
    <Helmet title='Доступное оборудование для печати штрихкодов, этикеток' />
    <img
      className={b('printer')}
      src={printer}
      alt='printer'
    />
    <Text type='h1'>Доступное оборудование штрихкодирования и автоматизации</Text>
    <Text type='primary' tag='p'>Для ресторанов, маркетплейсов и магазинов.</Text>
    <ul className={b('linksList')}>
      <Text className={b('catalogLink')} tag='li'><Link to='/catalog/label-printers'>Принтеры этикеток</Link></Text>
      <Text className={b('catalogLink')} tag='li'><Link to='/catalog/receipt-printers'>Принтеры чеков</Link></Text>
      <Text className={b('catalogLink')} tag='li'><Link to='/catalog/barcode-scanners'>Сканеры штрихкодов</Link></Text>
      <Text className={b('catalogLink')} tag='li'><Button as={RouterLink} to='/catalog'>Весь каталог</Button></Text>
    </ul>
  </div>
);

export default Hero;
